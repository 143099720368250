import React, { useRef, useCallback, useEffect } from 'react'

export const useClickOutside = (onClickOutside = () => {}, ref) => {
  const node = useRef(ref)
  const handleOutsideClick = useCallback(
    (e) => {
      if (node && node.current) {
        if ((node.current || [])?.contains(e?.target)) {
          return
        }

        e.preventDefault()
        e.stopImmediatePropagation()

        onClickOutside()
      }
    },
    [onClickOutside]
  )

  useEffect(() => {
    document.addEventListener('mouseup', handleOutsideClick)

    return () => {
      document.removeEventListener('mouseup', handleOutsideClick)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return node
}

export default useClickOutside
