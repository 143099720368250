import Image from 'next/image'

export const PotentialOfferBannerImage = () => {
  return (
    <div className="hidden lg:block">
      <Image
        priority
        src="/next-assets/section-hero-image-only-desktop.webp"
        alt="woman with long brown hair smiling because she has found debt relief"
        className="absolute m-auto object-cover lg:min-h-[622px]"
        fill={true}
      />
      <div className="absolute bottom-6 right-12 flex flex-col items-center text-sm font-normal">
        <p>Alejandra G.,</p>
        <p>Freedom client⁴</p>
      </div>
    </div>
  )
}

export const CustomerCenteredBannerImage = () => {
  return (
    <Image
      priority
      src="/next-assets/section-hero-image-only-desktop.webp"
      alt="woman with long brown hair smiling because she has found debt relief"
      className="relative h-[156px] w-full object-cover lg:absolute lg:min-h-[622px] lg:w-auto"
      fill={true}
    />
  )
}
