import { useMemo, useState, useCallback } from 'react'
import { useRouter } from 'next/router'
import Image from 'next/image'
import getConfig from 'next/config'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import ButtonLink from '../../../shared/ButtonLink'
import OldSliderCTA from 'src/components/shared/Slider'
import { PotentialOfferBannerImage } from '../BannerImage/index'
import { useAnalytics } from '../../../../common/analytics'
import { useLeadId } from '../../../../common/lead-id'
import { slider, checkIcon, calendarIcon, moneyIcon } from './index.module.scss'

import { paymentsReferenceTable } from './data'
import {
  numberFormat,
  findPaymentReferenceIndex,
  controlPhone,
  defaultPhoneNumber,
} from 'src/common/utils'
import useClickOutside from 'src/common/hooks/useClickOutside'
import { getCookies } from 'src/common/helpers'
import TrustpilotItem from 'src/components/shared/TrusPilotItem'
import { useNavigateToApply } from 'src/common/hooks/useNavigateToApply'

const { APPLY_FDR_URL } = getConfig()?.publicRuntimeConfig
const paymentsReferenceTableKeys = Object.keys(paymentsReferenceTable).map(
  (n) => parseInt(n, 10)
)

const amountFormatOptions = {
  style: 'currency',
  currency: 'USD',
}

const SliderCTA = ({
  min = 5000,
  max = 150000,
  initialAmount = 25000,
  isHero = false,
  showTrusPilot = false,
  hiddeTitle = false,
  className = '',
  variation = 'control',
  isSimpleView = false,
  containerClassName = slider,
  valueClassName = '',
  sliderClassName = 'mb-3 w-full appearance-none rounded-lg bg-gray-55 bg-slider-track-2 bg-no-repeat outline-none h-[4px] w-full',
  applyUrl = `${APPLY_FDR_URL}/home/`,
}) => {
  const [isTooltipOpen, setIsTooltipOpen] = useState(false)
  const { track, utm_source } = useAnalytics()
  const { query: baseQuery } = useRouter()
  const cookieLeadId = useLeadId()
  const cookies = getCookies()
  const tooltipRef = useClickOutside(() => {
    setIsTooltipOpen(false)
  })
  const initialAmountIndex = paymentsReferenceTableKeys.indexOf(initialAmount)
  const [selectedAmount, setSelectedAmount] = useState({
    amount: initialAmount,
    lastAmountIndex: initialAmountIndex,
  })

  const navigateToApply = useNavigateToApply({
    utm_source,
    estimated_debt: selectedAmount.amount,
  })

  const paymentTable = useMemo(() => {
    const [
      othersMonthlyPayment,
      othersTotalCost,
      debtResolutionMonthlyPayment,
      debtResolutionTotalCost,
    ] =
      paymentsReferenceTable[
        paymentsReferenceTableKeys[selectedAmount.lastAmountIndex]
      ]

    return {
      othersMonthlyPayment,
      othersTotalCost,
      debtResolutionMonthlyPayment,
      debtResolutionTotalCost,
      freeUp: othersMonthlyPayment - debtResolutionMonthlyPayment,
    }
  }, [selectedAmount.lastAmountIndex])

  const handleOnSliderChange = async (e) => {
    const amount = Number(e.target.value)
    const paymentReferenceIndex = await findPaymentReferenceIndex(
      paymentsReferenceTableKeys,
      amount
    )
    setSelectedAmount((prevState) => ({
      amount,
      lastAmountIndex:
        paymentReferenceIndex > -1
          ? paymentReferenceIndex
          : prevState.lastAmountIndex,
    }))
  }

  const phoneClickParams = {
    click_type: 'Phone Click',
    track_event: 'phone_click',
    event_type: 'conversion',
    event_action: 'click',
  }

  const handleSliderClick = useCallback(
    (e, params = {}) => {
      e.preventDefault()
      const variationText = e.target.innerText
      const data = {
        nav_link_section: isHero ? 'hero-banner' : 'slider-cta',
        page_section: isHero ? 'Potential offer hero slider' : 'sliderTitle',
        click_type: 'Button Click',
        click_id: variationText,
        click_text: `FDR-Web | ${variationText}`,
        form_ss_amount: +selectedAmount.amount,
        track_event: 'button_click',
        ...params,
      }

      if (typeof track === 'function') {
        track(
          data,
          {
            event_type: 'conversion',
          },
          'button_click'
        )
      }

      navigateToApply()
    },
    [isHero, selectedAmount.amount, track, navigateToApply]
  )

  const openTooltip = (e) => {
    if (isTooltipOpen) return
    setIsTooltipOpen(true)
  }

  const query = useMemo(() => {
    const urlParams = {
      from: 'fdr',
      utm_source,
      ...baseQuery,
      estimated_debt: selectedAmount.amount,
      leadId: cookieLeadId,
      visit_id: cookies?.eh_visit_id || null,
    }

    for (let key in urlParams) {
      if (!urlParams[key]) {
        delete urlParams[key]
      }
    }

    const q = new URLSearchParams(urlParams)

    return q.toString()
  }, [
    baseQuery,
    cookieLeadId,
    cookies?.eh_visit_id,
    selectedAmount.amount,
    utm_source,
  ])

  const getSuperScriptNumbers = useCallback(
    () =>
      ['variant-4', 'variant-5', 'variant-6'].includes(variation)
        ? ['¹', '²', '³']
        : ['²', '³', '⁴'],
    [variation]
  )

  let phoneNumber = defaultPhoneNumber

  switch (variation) {
    case 'control':
      phoneNumber = '833-418-3210'
      break
    case 'potential_offer':
      phoneNumber = controlPhone
      break
    case 'customer_centered':
      phoneNumber = '833-582-2310'
      break
    default:
      phoneNumber = defaultPhoneNumber
      break
  }

  const isMaxAmountSelected = max === selectedAmount.amount

  return (
    <div
      id={isHero ? 'hero-slider-cta' : 'slider-cta'}
      data-testid={isHero ? 'hero-slider-cta' : 'slider-cta'}
      className={classNames(
        isHero
          ? `z-90 relative w-full`
          : 'm-auto flex items-center justify-center',
        'mt-4 bg-blue-135 md:mt-0 lg:bg-transparent'
      )}
    >
      <div className={`${isHero ? 'md:block' : 'hidden'}`}>
        <PotentialOfferBannerImage />
      </div>
      <div
        className={`w-content m-auto ${
          isHero ? 'pb-8' : 'pb-0'
        } lg:px-20 lg:pt-9 xl:px-32`}
      >
        <div
          className={classNames(
            'm-auto w-[335px] rounded-lg md:mt-[-1em] lg:ml-[2rem] lg:w-[430px] lg:bg-white lg:pb-0 lg:drop-shadow',
            className
          )}
        >
          {!hiddeTitle && (
            <h1 className="mb-[0.2rem] py-4 text-center text-32 font-semibold leading-[2.6rem] lg:p-2 lg:pb-0 lg:pl-10 lg:pr-12 lg:pt-[1.4rem]">
              Get rid of your debt with debt relief
            </h1>
          )}
          <div className="m-auto rounded-lg bg-white text-gray-555 drop-shadow lg:w-auto lg:drop-shadow-none">
            <div className="p-4 pb-2 text-center lg:p-2 lg:px-8">
              <h2 className="mb-1 pt-1 font-[500] lg:text-heading-3.5">
                How much debt do you have?
              </h2>
              <OldSliderCTA
                onChange={handleOnSliderChange}
                min={min}
                max={max}
                valueClassName={classNames(
                  'font-semibold text-blue-585 text-16 w-full text-center lg:text-heading-3.5 mb-[0.2rem]',
                  valueClassName
                )}
                sliderClassName={sliderClassName}
                containerClassName={containerClassName}
              />
              <div className="mb-1 flex justify-between text-12 text-neutral-300">
                <label>{numberFormat(min, amountFormatOptions)}</label>
                <label>{numberFormat(max, amountFormatOptions)}+</label>
              </div>
            </div>
            {!isSimpleView && (
              <div>
                <div className="w-full bg-green-40 text-center text-14 font-[500] lg:p-2 lg:text-16">
                  <span>You could free up</span>
                  <div className="ml-1 inline-block text-heading-3.5 font-semibold text-green-400">
                    {numberFormat(paymentTable.freeUp, amountFormatOptions)}
                    /mo{isMaxAmountSelected ? ' plus' : ''}
                    <span className="font-[500] lg:text-heading-3.5">
                      {getSuperScriptNumbers()[0]}
                    </span>
                    &nbsp;<span className="text-16">🎉</span>
                  </div>
                </div>
                <div className="relative grid grid-cols-3 p-[16px] text-left text-14 font-[500] lg:px-8">
                  <div></div>
                  <div className="px-1 py-2 pl-5 text-12 font-normal text-neutral-300">
                    Current
                  </div>
                  <div className="flex items-center justify-center gap-2 rounded-t-md bg-green-40 px-3 py-2 text-12 font-normal text-neutral-300">
                    <i className={checkIcon} /> Freedom
                  </div>
                  <div className="flex w-[120px] items-center  gap-1 px-1 py-2 pl-0 text-12 text-neutral-300">
                    <i className={calendarIcon} /> Mo payment
                  </div>
                  <div className="px-1 py-2 pl-5">
                    {numberFormat(
                      paymentTable.othersMonthlyPayment,
                      amountFormatOptions
                    )}
                    /mo
                    <span className="text-base">
                      {getSuperScriptNumbers()[1]}
                    </span>
                  </div>
                  <div className="bg-green-40 px-1 py-2 pt-3 text-center">
                    {numberFormat(
                      paymentTable.debtResolutionMonthlyPayment,
                      amountFormatOptions
                    )}
                    /mo
                  </div>
                  <div
                    className={classNames(
                      'absolute left-0 top-[-0.6rem] h-[88px] w-[265px] drop-shadow lg:left-[-2.5rem]',
                      !isTooltipOpen && 'hidden'
                    )}
                    ref={tooltipRef}
                  >
                    <div className="absolute bottom-[-0.5rem] left-[1.2rem] z-10 h-4 w-4 rotate-45 bg-neutral-100 lg:left-[8rem]" />
                    <div className="absolute z-20 rounded bg-neutral-100 p-4 text-left text-13">
                      <div
                        className="absolute right-[0.5rem] top-[0.5rem] h-4 w-4 cursor-pointer"
                        onClick={() => setIsTooltipOpen(false)}
                      >
                        <Image
                          priority
                          src="/next-assets/icons/close.svg"
                          alt="close tooltip"
                          width={10}
                          height={10}
                        />
                      </div>
                      <span className="font-bold">Est. total cost</span>
                      <br />
                      <span className="font-light">
                        Your starting debt amount and added interest built up
                        over time.
                      </span>
                    </div>
                  </div>
                  <div
                    className="flex w-[120px] cursor-pointer  items-center gap-1 px-1 py-2 pl-0 text-12 text-neutral-300"
                    onClick={openTooltip}
                  >
                    <i className={moneyIcon} /> <u>Est. total cost</u>
                  </div>
                  <div className="px-1 py-2 pl-5">
                    {numberFormat(
                      paymentTable.othersTotalCost,
                      amountFormatOptions
                    )}
                    <span className="text-base">
                      {getSuperScriptNumbers()[2]}
                    </span>
                  </div>
                  <div className="rounded-b-md bg-green-40 p-2 pb-0 text-center">
                    {numberFormat(
                      paymentTable.debtResolutionTotalCost,
                      amountFormatOptions
                    )}
                  </div>
                </div>
              </div>
            )}

            <div className="flex flex-col items-center px-4 pt-1 lg:px-8">
              <a
                id={isHero ? 'hero-cta' : 'slider-cta'}
                href={`${applyUrl}?${query}`}
                onClick={handleSliderClick}
                className={`flex w-full justify-center rounded-md ${
                  isSimpleView ? 'bg-blue-550' : 'bg-red-850'
                } px-4 py-2.5`}
                rel="noreferrer"
              >
                <span className="mr-[6px] text-lg font-extrabold text-white">
                  {showTrusPilot
                    ? `See if you save ${numberFormat(
                        paymentTable.freeUp,
                        amountFormatOptions
                      )}/mo${isMaxAmountSelected ? '+' : ''}`
                    : 'Continue'}
                </span>
                {!showTrusPilot && (
                  <Image
                    priority
                    src="/next-assets/icons/arrow-right-circle-negative.svg"
                    alt="light arrow down"
                    height={20}
                    width={20}
                    className="h-auto w-auto"
                  />
                )}
              </a>
            </div>
            {isSimpleView ? (
              <div className="h-[15px]"></div>
            ) : (
              <div className="pt-3 pb-2">
                <ButtonLink
                  onClick={(_, e) => handleSliderClick(e, phoneClickParams)}
                  href={`tel:${phoneNumber}`}
                  className="w-full bg-white text-14 font-semibold text-blue-585 lg:text-16 lg:font-[500]"
                >
                  Or call {phoneNumber}
                </ButtonLink>
              </div>
            )}
            {showTrusPilot && (
              <div className="flex flex-col items-center pb-4 pt-1">
                <TrustpilotItem
                  starClassName="w-5.5 h-5.5"
                  trustScoreClassName="text-base"
                  trustpilotClassName="text-base"
                  trustpilotRatingClassName="text-base"
                  starsGroupClassName="w-20 h-5 mx-2.5"
                  starsGroupImgClassName="pt-1"
                  isHome={true}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

SliderCTA.propTypes = {
  min: PropTypes.number,
  max: PropTypes.number,
  initialAmount: PropTypes.number,
  isHero: PropTypes.bool,
  hiddeTitle: PropTypes.bool,
  className: PropTypes.string,
  showTrusPilot: PropTypes.bool,
  isSimpleView: PropTypes.bool,
  containerClassName: PropTypes.string,
  valueClassName: PropTypes.string,
  sliderClassName: PropTypes.string,
  applyUrl: PropTypes.string,
}

export default SliderCTA
